@import "~antd/lib/style/themes/default.less";

// Override the default .less

// Color palettes
@white: #FFFFFF;
@blue-6: #2196f3;
@green-6:#37BC9B;
@red-5: #E53434;
@gold-6: #F0B500;

@primary-color: @blue-6;
@info-color: @primary-color;
@success-color: @green-6;
@processing-color: @blue-6;
@highlight-color: @red-5;
@error-color: @red-5;
@missing-color: #E53434;
@incomplete-color: #EB5757;
@warning-color: #F2994A;
@scheduled-color: #F2B90C;
@filter-color: #ECA400;
@submit-color: #37BC9B;
@publish-color: #37BC9B;
@normal-color: #d9d9d9;

// Logo background
@logo-background-color: @white;
@logo-border-color: #F0810F;

// Color used by default to control hover and active backgrounds and for
// alert info backgrounds.

// Base Scaffolding Variables
// ---
@main-background: #0099de;
@body-background: #eeeded;
@component-background: #fff;
@font-family: Roboto, Helvetica, Arial, sans-serif;
@text-color: #29364D;
@text-color-secondary: #8497B0;
@text-color-inverse: @white;
@icon-color: inherit;
@icon-color-hover: fade(@black, 75%);
@heading-color: #29364D;
@heading-color-dark: fade(@white, 100%);
@text-color-dark: fade(@white, 85%);
@text-color-secondary-dark: fade(@white, 65%);
@text-selection-bg: @primary-color;
@font-size-base: 14px;
@font-size-lg: 16px;
@font-size-md: 14px;
@font-size-sm: 12px;
@heading-1-size: 32px;
@heading-2-size: 24px;
@heading-3-size: 20px;
@heading-4-size: 16px;
@font-bold: 700;
@font-medium: 500;
@font-normal: 400;
@font-thin: 300;

// Content Editor
@editor-icon-color: #485875;

@border-radius-base: 5px;
@border-radius-sm: @border-radius-base;

@text-color-bg: #29364D;
@text-color-secondary-bg: #8497B0;

// Layout
@layout-body-background: #eeeded;
@layout-footer-background: @layout-body-background;
@layout-header-height: 48px;
@layout-expanded-width: 256px;
@layout-collapsed-width: 80px;
@layout-header-padding: 0 50px;
@layout-footer-padding: 24px 50px;
@layout-sider-background: #FFF;
@layout-trigger-height: 48px;
@layout-trigger-background: #FFF;
@layout-trigger-color: #fff;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 42px;

// vertical paddings
@padding-xl: 24px;
@padding-lg: 20px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-base: 8px; // small base
@padding-xs: 4px; // small items

// Icon
@icon-body-color-bg: #F6F8FC;

// LINK
@link-color: @primary-color;
@link-hover-color: color(~`colorPalette('@{link-color}', 5) `);
@link-active-color: color(~`colorPalette('@{link-color}', 7) `);

// z-index header
@zindex-header: 1009;

// z-index list, order by `z-index`
@zindex-badge: auto;
@zindex-table-fixed: 2;
@zindex-affix: 10;
@zindex-back-top: 10;
@zindex-picker-panel: 10;
@zindex-popup-close: 10;
@zindex-modal: 1000;
@zindex-modal-mask: 1000;
@zindex-drawer: 1010;
@zindex-picker-dialog: 1011;
@zindex-message: 1020;
@zindex-notification: 1020;
@zindex-popover: 1060;
@zindex-dropdown: 1050;
@zindex-picker: 1050;
@zindex-popoconfirm: 1060;
@zindex-tooltip: 1070;
@zindex-image: 1080;

// Animation

// Border color
@border-color-base: #F2F2F2;
@border-color-split: #F2F2F2;
@border-color-divider-orientation: #5d6e98;
@border-color-divider-orientation-bg: #d3d8e4;

// Outline
// Outline
@outline-blur-size: 0;
@outline-width: 2px;
@outline-color: @primary-color;
@outline-fade: 20%;

@background-color-light: #F6F8FC; // background of header and selected item
@background-color-base: hsv(0, 0, 96%); // Default grey background color


// Disabled states


// Shadow

// Buttons
@btn-padding-base: 0 @padding-sm;
@btn-font-size-lg: 14px;
@btn-font-size-sm: 14px;
@btn-padding-lg: @btn-padding-base;
@btn-height-base: 42px;
@btn-height-lg: 42px;
@btn-height-sm: 24px;

@btn-primary-bg: @primary-color;

@btn-danger-color: #fff;
@btn-danger-bg: #de350b;
@btn-danger-border: #de350b;

@btn-fixed-page-top: 104px;

// Chart
@chart-color: @black;
@chart-fill: @black;


// Checkbox

// Dropdown
@dropdown-selected-color: @heading-color;

// Radio


// Radio buttons

// Media queries breakpoints
// @screen-xs and @screen-xs-min is not used in Grid
// smallest break point is @screen-md
@screen-xs: 480px;
@screen-xs-min: @screen-xs;
// 👆 Extra small screen / phone

// 👇 Small screen / tablet
@screen-sm: 576px;
@screen-sm-min: @screen-sm;

// Medium screen / desktop
@screen-md: 768px;
@screen-md-min: @screen-md;

// Large screen / wide desktop
@screen-lg: 992px;
@screen-lg-min: @screen-lg;

// Ipad Pro screen
@screen-ipad-pro: 1024px;
@screen-ipad-pro-min: @screen-lg;

// Extra large screen / full hd
@screen-xl: 1200px;
@screen-xl-min: @screen-xl;

// Extra extra large screen / large desktop
@screen-xxl: 1600px;
@screen-xxl-min: @screen-xxl;

// provide a maximum
@screen-xs-max: (@screen-sm-min - 1px);
@screen-sm-max: (@screen-md-min - 1px);
@screen-md-max: (@screen-lg-min - 1px);
@screen-lg-max: (@screen-xl-min - 1px);
@screen-xl-max: (@screen-xxl-min - 1px);

// Grid system
@grid-columns: 24;

// Grid system

@alert-success-border-color: @success-color;
@alert-success-icon-color: @success-color;
@alert-info-border-color: @info-color;
@alert-info-icon-color: @info-color;
@alert-warning-border-color: @warning-color;
@alert-warning-icon-color: @warning-color;
@alert-error-border-color: @error-color;
@alert-error-icon-color: @error-color;
@alert-text-color: @text-color;

// End of the default.less

// Set colors for default theme
:root {
    --switch-color: #3C4EBC;
    --switch-color-f80    : fade(@primary-color, 80%);

    --primary-color         : #2196f3;
    --info-color            : #3C4EBC;
    --processing-color      : #3C4EBC;
    --link-color            : #3C4EBC;
    --link-hover-color      : #4959c5;
    --success-color         : #37BC9B;
    --submit-color          : #37BC9B;
    --publish-color         : #37BC9B;
    --error-color           : #E53434;
    --missing-color         :#E53434;
    --incomplete-color      : #EB5757;
    --warning-color         : #F2994A;
    --scheduled-color         : #F2B90C;
    --filter-color         : #ECA400;

    --menu-highlight-color   : #3C4EBC;
    --input-hover-border-color : #3C4EBC;
    --menu-light-theme-bg: #FFFFFF;
    --menu-light-theme-icon-bg: #F6F8FC;
    --menu-light-theme-color-base: #8497B0;
    --item-hover-bg: #F5F5F5;

    --body-background: #eeeded;
    --background-color-light: #eeeded;
    --layout-body-background: #eeeded;
    --student-info-background: #dbe3eb;
    --component-background: #FFFFFF;
    --component-background-f80     : fade(@component-background, 80%);
    --default-background-gray: #F5F5F5;

    --text-color: #29364D;
    --heading-color: #29364D;
    --text-color-secondary: #8497B0;

    --border-color-darker: #E5E5E5;
    --border-color-base: #F2F2F2;
    --border-color-split: #F2F2F2;

    --black-color: #000000;
    --disabled-color: fade(@black, 25%);
    --disabled-bg: fade(@black, 12%);
    --disabled-input-color: fade(@black, 25%);
    --disabled-input-bg: "#F5F5F5";
    --radio-dot-disabled-color: fade(@black, 20%);
    --shadow-color: #DADADA;
    --shadow-color-f6: fade(@shadow-color, 6%);
    --shadow-color-f7: fade(@shadow-color, 7%);
    --skeleton-color: #e1e8ef;

    --table-bg-color: #fafafa;

    --success-color-f10     : fade(@success-color, 10%);
    --submit-color-f10      : fade(@submit-color, 10%);
    --publish-color-f10      : fade(@publish-color, 10%);
    --primary-color-f10     : fade(@primary-color, 10%);
    --primary-color-f20     : fade(@primary-color, 20%);
    --primary-color-f30     : fade(@primary-color, 30%);
    --primary-color-f40     : fade(@primary-color, 40%);
    --primary-color-f50     : fade(@primary-color, 50%);
    --primary-color-f60     : fade(@primary-color, 60%);
    --primary-color-f70     : fade(@primary-color, 70%);
    --primary-color-f80     : fade(@primary-color, 80);
    --tabs-hover-color     : fade(@primary-color, 70%);

    --info-color-d8         : darken(@info-color, 8%);
    --warning-color-l38     : lighten(@warning-color, 38%);
    --warning-color-f10     : fade(@warning-color, 10%);
    --scheduled-color-f10    : fade(@scheduled-color, 10%);
    --filter-color-f10    : fade(@filter-color, 10%);
    --error-color-f10       : fade(@error-color, 10%);
    --missing-color-f10       : fade(@missing-color, 10%);
    --missing-color-f20       : fade(@missing-color, 20%);
    --incomplete-color-f10       : fade(@incomplete-color, 10%);
    --text-color-secondary-f10: fade(@text-color-secondary, 10%);
    --text-color-secondary-f24: fade(@text-color-secondary, 24%);
    --text-color-secondary-l10: lighten(@text-color-secondary, 10%);
    --affix-bg-color-f94     : fade(@component-background, 94%);
}
