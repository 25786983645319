body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #0099de;
  .logo{
    height: 2rem;
  }
}
// Right icons of top header nav
.header-nav-right-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span svg, i {
        color: var(--menu-light-theme-color-base);
    }
    svg {
        fill: var(--menu-light-theme-color-base);
    }
    .text-mobile-only {
        display: none;
    }
}

.dd-settings {
  min-width: 200px;
  background-color: var(--component-background);
  box-shadow: 0px 4px 20px var(--shadow-color);
  border-radius: 5px;
  padding-top: @padding-xs * 2;
  padding-bottom: @padding-xs * 2;

  .circle-bg {
      width: 28px;
      height: 28px;
      background: var(--menu-light-theme-icon-bg);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 70% auto;
      border-radius: 50%;
      transition: all 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;

      > span {
          margin-right: 0;
      }
  }

  &::before {
      position: absolute;
      top: -12px;
      right: 24px;
      content: '';
      border-bottom: 16px solid var(--component-background);
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
  }
}
.avatarSetting {
  &:hover {
    border: 2px solid var(--primary-color);
  }
}


// Menu
.@{menu-prefix-cls} {
  background-color: var(--component-background);
  color: var(--text-color);
  &-dark &-submenu-title > a {
      text-decoration: none;
      color: var(--text-color);
  }
  &-dark &-inline&-sub {
      box-shadow: none;
  }
  &-item a {
      color: var(--text-color);
  }
  &-item,
  &-submenu-title {
      .@{iconfont-css-prefix} {
          font-size: 16px;
          margin-right: 6px;
          > svg {
              margin-bottom: 0;
          }
      }
  }
  &-submenu-selected {
      color: var(--menu-highlight-color);
  }
  &-item:active,
   &-submenu-title:active {
      background: var(--primary-color-f10);
  }
  &-item > a {
      color: var(--text-color);
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
          color: var(--menu-highlight-color);
          text-decoration: none;

          .class-upcoming {
              color: var(--menu-highlight-color) !important;
          }
      }
  }
  &-item a:hover {
      color: var(--menu-highlight-color);
      text-decoration: none;
  }
  &-item:hover,
  &-item-active,
  &:not(&-inline) &-submenu-open,
  &-submenu-active,
  &-submenu-title:hover {
      color: var(--menu-highlight-color);
  }
  &&-dark &-item-selected,
  &-submenu-popup&-dark &-item-selected {
      > a {
          color: @white;
      }
      &.level-1 {
          > a i {
              color: @white;
          }
      }
  }
  &-vertical,
  &-vertical-left,
  &-vertical-right,
  &-inline {
      border-right-color: var(--border-color-base);
      .@{menu-prefix-cls}-item {
          &::after {
              border-right: @menu-item-active-border-width solid var(--menu-highlight-color);
          }
      }
      .@{menu-prefix-cls}-item,
      .@{menu-prefix-cls}-submenu-title {
          margin-top: 0;
          margin-bottom: 4px;
          &.menu-item-select-class-list {
              height: unset;
              padding-left: 0;
              padding-right: 0;
              margin-bottom: 0;
          }
      }
      .@{menu-prefix-cls}-item:not(:last-child) {
          margin-bottom: 0;
          &.grading-term {
              margin-bottom: 12px;
          }
      }
  }
  &-submenu {
      // submenu popup
      &-popup > .@{menu-prefix-cls} {

          > li {
              span svg, i {
                  width: 20px;
                  font-size: 16px;
                  margin-right: 8px;
              }
          }
      }
      &-vertical,
      &-vertical-left,
      &-vertical-right,
      &-inline {
          > .@{menu-prefix-cls}-submenu-title .@{menu-prefix-cls}-submenu-arrow {
              &::before,
              &::after {
                  background-image: linear-gradient(to right, var(--text-color), var(--text-color));
              }
          }
          > .@{menu-prefix-cls}-submenu-title:hover .@{menu-prefix-cls}-submenu-arrow {
              &::after,
              &::before {
                background: linear-gradient(to right, var(--menu-highlight-color), var(--menu-highlight-color));
              }
          }
      }
  }
  &.main-nav {
      .level-1 {
          > a {

              i {
                  color: var(--text-color);
              }
              span {
                  font-weight: 500;
              }
          }
          &-admin {
              .@{menu-prefix-cls}-submenu-title .@{menu-prefix-cls}-submenu-arrow {
                  visibility: hidden;
              }
          }
          &:last-child {
              margin-bottom: 24px;
          }
      }
      .level-2 {
          padding-left: 36px !important;
      }
  }
  // Top nav menu
  &-horizontal&.top-menu-header {
      height: 100%;
      margin: 0 0 0 0;
      line-height: 20px;
      border-bottom: 0;
      width: calc(100% - 127px);

      &:not(.@{menu-prefix-cls}-dark) {
          > .@{menu-prefix-cls}-item,
          > .@{menu-prefix-cls}-submenu {
            margin-left: 0;
            margin-top: -1px;
            margin-bottom: 0;
            padding: 0;

            &:hover,
            &-active,
            &-open,
            &-selected {
              border-top: 0px solid var(--menu-highlight-color);
              border-bottom: 0px solid var(--menu-highlight-color);
              margin-bottom: 0px;
              a:hover {
                  text-decoration: none;
              }
              &::after {
                  border-bottom: 0px solid var(--menu-highlight-color);
                }
            }
          }
      }

      > .@{menu-prefix-cls}-item,
      > .@{menu-prefix-cls}-submenu {
          height: 100%;
          overflow: hidden;
          border-top: 0px solid transparent;
          border-bottom: 0px solid transparent;

          &::after {
              border-bottom: 2px solid transparent;
              transition: unset;
          }
          .ant-menu-title-content > a {
              color: var(--menu-light-theme-color-base);
              margin-right: @padding-xl;
              &:only-child, .ant-menu-submenu-title {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
              }
              span {
                  &.circle-bg {
                      width: 28px;
                      height: 28px;
                      background: var(--menu-light-theme-icon-bg);
                      background-repeat: no-repeat;
                      background-position: 50%;
                      background-size: 70% auto;
                      border-radius: 50%;
                      transition: all 0.3s ease;
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                      > span {
                          margin-right: 0;
                      }
                      > span svg, i {
                          font-size: 14px;
                      }
                      svg {
                          fill: var(--menu-light-theme-color-base);
                          &.ePortfolio {
                              fill: var(--menu-light-theme-icon-bg);
                              stroke: var(--menu-light-theme-color-base);
                          }
                      }
                  }
              }
          }
          // Placeholder button style for buttons with no permissions
          &.placeholder {
              padding: 0;
              margin-left: 0;
              margin-right: 0;
          }
          &.right-align{
              float: right;
          }
          &:hover,
          &-active,
          &-open,
          &-selected {
              border-top: 0px solid var(--menu-highlight-color);
              border-bottom: 0px solid var(--menu-highlight-color);
              margin-bottom: 0px;
              .ant-menu-title-content > a {
                  color: var(--link-color);
                  span.circle-bg {
                      background-color: var(--menu-highlight-color);
                      > span svg, i {
                          color: @white;
                      }
                      svg {
                          fill: @white;
                      }
                  }
              }
              &.revert {
                  background-color: transparent;
                  color: unset;
                  margin-bottom: 0;
                  padding-top: 4px;
              }
          }

          &-disabled {
              > i {
                  color: var(--menu-light-theme-color-disabled) !important;
                  margin-bottom: 2px;
              }
              > .text {
                  color: var(--disabled-color);
                  color: var(--menu-light-theme-color-disabled) !important;
              }
          }

          &.coming-soon:before{
              width: 100px;
              height: 16px;
              line-height: 14px;
              transform: rotate(-45deg);
              text-align: center;
              position: absolute;
              background: #26862dd1;
              top: 45px;
              left: 10px;
              color: white;
              font-size: 8px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 1px;
              content: "Coming soon";
          }

          // When menu item is hidden: show ... menu
          &.ant-menu-overflowed-submenu, &.ant-menu-overflow-item-rest {
              .ant-menu-submenu-title {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  > span {
                      font-size: 20px;
                      margin-right: @padding-xl
                  }
              }
          }

          @media screen and (max-width: @screen-sm) {
              padding-left: 8px;
              padding-right: 8px;
          }

      }
      &.light-menu {
          height: @layout-header-height;
          background-color: var(--menu-light-theme-bg);
          color: var(--menu-light-theme-color-base);
          &.center {
              text-align: center;
          }
      }

  }
  &-horizontal {
      &:not(.@{menu-prefix-cls}-dark) {
          > .@{menu-prefix-cls}-item,
          > .@{menu-prefix-cls}-submenu {
              &:hover,
              &-active,
              &-open,
              &-selected {
                  border-top: 0px solid var(--menu-highlight-color);
                  border-bottom: 0px solid var(--menu-highlight-color);
                  margin-bottom: 0px;
                  a:hover {
                      text-decoration: none;
                  }
                  &::after {
                      border-bottom: 0px solid var(--menu-highlight-color);
                  }
              }
          }
      }
      > .@{menu-prefix-cls}-item,
      > .@{menu-prefix-cls}-submenu {
          height: 100%;
          overflow: hidden;
          border-top: 0px solid transparent;
          border-bottom: 0px solid transparent;

          &::after {
              border-bottom: 2px solid transparent;
              transition: unset;
          }
          .ant-menu-title-content > a {
              &:only-child, .ant-menu-submenu-title {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
              }
          }
      }
  }
  &-submenu {
      > .ant-menu {
          background-color: var(--component-background);
      }
      &-popup {
          ul li a {
              display: flex;
              align-items: center;
              span {
                  &.circle-bg {
                      width: 28px;
                      height: 28px;
                      margin-right: 8px;
                      background: var(--menu-light-theme-icon-bg);
                      background-repeat: no-repeat;
                      background-position: 50%;
                      background-size: 70% auto;
                      border-radius: 50%;
                      transition: all 0.3s ease;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      > span {
                          margin-right: 0;
                      }
                      > span svg, i {
                          font-size: 14px;
                          width: auto;
                          margin-right: 0;
                          color: var(--menu-light-theme-color-base);
                      }
                      svg {
                          fill: var(--menu-light-theme-color-base);
                          margin-right: 0;
                      }
                  }
              }
          }
      }
  }
  &-item-selected {
      color: var(--link-color);
      a,
      a:hover {
          color: var(--link-color);
      }
      a > .class-list.class-upcoming {
          color: var(--link-color) !important;
      }
  }
  // Disabled state sets text to gray and nukes hover/tab effects
  &-item-disabled,
  &-submenu-disabled {
      color: var(--disabled-color) !important;
      a {
          color: var(--disabled-color) !important;
      }
      > .@{menu-prefix-cls}-submenu-title {
          color: var(--disabled-color) !important;
          > .@{menu-prefix-cls}-submenu-arrow {
              &::before,
              &::after {
                  background: var(--disabled-color) !important;
              }
          }
      }
  }
  &:not(&-horizontal) &-item-selected {
      background-color: var(--primary-color-f10);
  }
}

.footer-main {
    background-color: @white;
}
.ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px #00000004;
    cursor: pointer;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: #000000d9;
    border-color: #d9d9d9;
    background: #fff;

    &.ant-btn-primary {
        color: #fff;
        border-color: var(--primary-color);
        background: var(--primary-color);
        text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
        box-shadow: 0 2px #0000000b;
    }
}
.logout-link{
    color: white;
    background-color: inherit;
    border: none;
}
.logout-icon{
    color: white;
    background-color: inherit;
    border: none;
    font-weight: bold;
}
.anticon{
    vertical-align: 0;
}
